<template>
  <div class="confirm-account login-component">
    <div class="image-container"></div>
    <transition appear name="fadeRight" mode="out-in">
      <form @submit.prevent="sendForgotPasswordConfirm()" id="forgot-password-form-confirm" class="login-form black-background">
        <div class="container">
          <h2>{{lang.forgotPasswortConfirm.headline1}}</h2>
          <md-field v-show="false">
            <label>{{lang.spielerForm.labelEMail}}</label>
            <md-input :name="lang.spielerForm.labelEMail" id="email" autocomplete="email" type="email"></md-input>
          </md-field>
          <md-field class="md-field-icon" :class="$v.passwortData.passwortNew.$invalid && $v.passwortData.passwortNew.$dirty ? 'md-invalid' : ''">
            <i class="fas fa-unlock"></i>
            <label>{{lang.spielerForm.labelPasswortNew}}</label>
            <md-input @blur="$v.passwortData.passwortNew.$touch()" :name="lang.spielerForm.labelPasswortNew" id="passwortNew" v-model="passwortData.passwortNew" autocomplete="new-password" type="password"></md-input>
            <span class="md-error" v-if="!$v.passwortData.passwortNew.required">{{lang.spielerDetail.passwortNewRequired}}</span>
            <span class="md-error" v-else-if="!$v.passwortData.passwortNew.minLength">{{$v.passwortData.passwortNew.$params.minLength.min}} {{lang.spielerForm.passwortMinLength}}</span>
          </md-field>
          <md-field class="md-field-icon" :class="$v.passwortData.passwortConfirm.$invalid && $v.passwortData.passwortConfirm.$dirty ? 'md-invalid' : ''">
            <i class="fas fa-lock"></i>
            <label>{{lang.spielerForm.labelPasswortRepeat}}</label>
            <md-input @blur="$v.passwortData.passwortConfirm.$touch()" :name="lang.spielerForm.labelPasswortRepeat" id="passwortConfirm" v-model="passwortData.passwortConfirm" autocomplete="new-password" type="password"></md-input>
            <span class="md-error" v-if="!$v.passwortData.passwortConfirm.sameAsPassword">{{lang.spielerForm.passwortConfirm}}</span>
          </md-field>
          <div class="button-list">
            <md-button type="submit" class="md-primary md-round md-raised md-center">
              {{lang.forgotPasswortConfirm.buttonText}}
            </md-button>
            <router-link :to="{name: 'Login'}">
              <md-button class="md-primary md-round md-raised md-center md-border">
                {{lang.forgotPasswortConfirm.buttonTextBack}}
              </md-button>
            </router-link>
          </div>
        </div>
      </form>
    </transition>
    <SubmitModal/>
    <InformationModal/>
    <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
  </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import SubmitModal from '../Utils/SubmitModal'
import InformationModal from '../Utils/InformationModal'
import {required, minLength, sameAs} from 'vuelidate/lib/validators'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'ForgotPasswordConfirm',
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal,
    InformationModal,
  },
  data() {
    return {
      passwortData: {
        passwortNew: '',
        passwortConfirm: ''
      }
    }
  },
  validations: {
    passwortData: {
      passwortNew: {
        required,
        minLength: minLength(5)
      },
      passwortConfirm: {
        sameAsPassword: sameAs('passwortNew')
      }
    }
  },
  methods: {
    async sendForgotPasswordConfirm() {
      if (this.isOffline) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
      } else {
        this.$v.passwortData.$touch();
        const requestData = {
          confirmationToken: this.$route.query.token,
          passwortNew: this.passwortData.passwortNew
        }
        if (!this.$v.passwortData.$invalid) {
          await store.dispatch('forgotPasswordConfirm', requestData)
            .then(result => {
              if(result.data != null) {
                this.showDialogActive(true, this.lang.forgotPasswortConfirm.successHeadline, "");
                setTimeout(() => { 
                  store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false);
                  router.push({name: "Login"});
                }, store.state.submitModalSuccessSeconds);
              } else {
                this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage);
              }
            })
            .catch(error => {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage)
            });
        }
      }
    },
    getValidationClass(fieldName) {
      return {
        'md-invalid': this.isFieldInvalid(fieldName)
      }
    },
    isFieldInvalid(fieldName) {
      const field = this.$v.passwortData[fieldName]
      if (field) {
        return field.$invalid && field.$dirty;
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
        store.commit("SET_SHOW_SUBMIT_MODAL", {
          show: true,
          success: formSuccess,
          headline: formSuccessHeadline,
          message: formSuccessMessage
      });
    },
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    loadingStatus() {
      return store.state.spieler.loadingStatus;
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';

.image-container {
  background-image: url("/img/beerpong-images/500px/beer-pong-team-mobile-5.jpg");
  background-position: 0% -3%;
}

h2 {
  margin-bottom: 10px;
}

.button-list {
  margin-top: 40px;
}

@media (min-width: $screen-xs2) {
  .image-container {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-5.jpg");
    background-position: 0% -10%;
  }
}

@media (max-width: $screen-sm) {

}
@media (min-width: $screen-sm) {
  .login-component {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-2.jpg");
    background-position: center bottom;
  }

  .container {
    padding-top: 80px;
  }
}

@media (min-width: $screen-lg) {
  .login-component {
      background-image: url("/img/beerpong-images/1920px/beer-pong-team-desktop-2.jpg");
      background-position: center bottom;
  }
}

</style>
